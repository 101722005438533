import React, { useState } from 'react'
import { gsap } from 'gsap'
import { srcSetProps, sanityImageUrl } from '../../../utils/format'
import cx from 'classnames'
import styles from './style.module.sass'

export default ({ ceo }) => {
  if (!ceo || ceo.isHide) return null

  const [opened, setOpened] = useState(false)

  const clickHandler = () => {
    const faqElement = document.querySelector(`.${styles.togglerContent}`)
    if (opened) {
      gsap.to(faqElement, { height: '0', duration: 0.3 })
      setOpened(false)
    } else {
      gsap.to(faqElement, { height: 'auto', duration: 0.3 })
      setOpened(true)
    }
  }

  return (
    <div className={styles.ceo}>
      <div className={styles.wrap}>
        <div className={styles.image}>
          <img {...srcSetProps(sanityImageUrl(ceo.image))} className={styles.imageSrc} alt={ceo.image?.caption} />
        </div>
        <div className={styles.text}>
          <p className={styles.info}>{ceo.description}</p>
          <div className={cx(styles.toggler, {
            [styles.togglerOpened]: opened
          })}>
            <p className={styles.togglerTitle} onClick={clickHandler}>{ceo.moreAboutText}</p>
            <p className={styles.togglerContent}>{ceo.moreAboutContent}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
