import React from 'react'
import { srcSetProps, sanityImageUrl } from '../../../utils/format'
import styles from './style.module.sass'

export default ({ header }) => {
  if (!header) return null

  return (
    <div className={styles.header}>
      <div className={styles.wrap}>
        <div className={styles.info}>
          <p className={styles.titleSmall}>{header.smallTitle}</p>
          <h1 className={styles.title}>{header.title}</h1>
          <p className={styles.text}>{header.info}</p>
        </div>
        <div className={styles.image}>
          <img {...srcSetProps(sanityImageUrl(header.image))} className={styles.imageSrc} alt={header.image?.caption} />
        </div>
      </div>
    </div>
  )
}
